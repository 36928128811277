import { FRENCH_TRANSLATIONS_SW_THERMOMETRY } from "sw-studio-thermometry/src/translations/fr";


export const FRENCH_TRANSLATIONS = {
  language: "fr",

  common: {
    groups: "groupes",
    group: "groupe",
    sites: "sites",
    site: "site",
    record: "Enregistrement",
    records: "Enregistrements",
    buildings: "bâtiments",
    building: "bâtiment",
    levels: "niveaux",
    level: "niveau",
    blueprints: "plans",
    blueprint: "plan",
    gateways: "passerelles",
    sensors: "capteurs",
    total: "Total",
    error: "Erreur",
    address: "Adresse",
    surface: "Surface",
    users: "utilisateurs",
    zones: "zones",
    parameterSets: "jeux de paramètres",
    walls: "murs",
    equipmentModels: "Modèles",
    equipmentFamilies: "Familles",
    browse: "parcourir",
    name: "Nom",
    description: "Description",
    action: "action",
    view: "vue",
    selected: "sélectionnés",
    algo: "Algorithme",
    success: "Opération réussie",
    connections: "Connexions",
    material: "Matériel",
    materialType: "Type de Matériel",
    type: "Type",
    parameters: "Paramètres",
    device: "Appareil",
    tag: "Kit",
    connectionType: "Type de connexion",
    connection: "Connexion",
    attributes: "Attributions",
    dashboard: "Dashboard",
    lastSignal: "Dernier signal",
    paired: "Appairé",
    battery: "Batterie",
    choose: "Choisir",
    mails: "Mails",
    search: "Je cherche : ",
    models: "Modèles",
    model: "Modèle",
    serial_number: "Numéro de série",
    save: "Enregistrer",
    family: "Famille",
    import: "Importer",
    loading: "Chargement",
    id: "Identifiant",
    service: "Service",
    start: "Début",
    previous: "Préc.",
    next: "Suivante",
    last: "Fin",
    byPage: "Par page :",
    lastMaintenanceDate:"Dernière maintenance",
    nextMaintenanceDate:"Prochaine maintenance",
    sensor:"Capteur",
    comment: "Commentaire",
    from: "Du",
    to: "au",
    options: "Options",
    thermometry: "Thermométrie",
    by: "par"
  },

  user: {
    deconnexion: "Se déconnecter",
    my_space: "Mon espace",
    back: "Retour",
    profile: {
      update: "Mise à jour",
      wrong_code: "Veuillez entrer un code à 6 chiffres."
    }
  },

  dashboard: {
    empty: 'Aucune alerte'
  },

  errors: {
    creation: "La création a échouée.",
    update: "La mise à jour a échouée.",
    delete: "La suppression a échouée.",
    import: "L'import a échoué.",
    data: "La récupération des données a échouée.",
    fileSize: "Le fichier excède la taille maximum ({maxSize} bytes)",
    optim: "L'optimisation a échouée.",
    emptyData: "Aucune donnée disponible",
    'This field is required.' : 'Ce champs est obligatoire',
    missingName: "Veuillez entrer le nom",
    missingFirstname: "Veuillez entrer le prénom",
    missingEmail: "Veuillez entrer l'émail",
    missingCell: "Veuillez entrer le numéro de téléphone",
    missingRole: "Veuillez séléctionner un role",
    error_sensor_type: "L'équipement est déjà appairé avec un capteur de type"
  },

  login: {
    email: "Email",
    identifier: "Identifiant",
    code: "Code",
    password: "Mot de passe",
    password2: "Confirmation du mot de passe",
    save: "Continuer",
    invalidCredentials: "Vos identifiants sont incorrects",
    welcome: "Bienvenue",
    connection: "Connexion",
    register: "S'inscrire",
    forgotPassword: "Mot de passe oublié",
    askValidation:
      "Afin de finaliser la création de votre compte, veuillez saisir un mot de passe.",
    lastname: "Nom",
    firstname: "Prénom",
    service: "Service",
    position: "Fonction",
    back : "Retour",
    passwordIndicators: {
      lengthMin: "Doit contenir au moins 8 caractères",
      formatLower: "Doit contenir au moins une minuscule",
      formatUpper: "Doit contenir au moins une majuscule",
      formatNumber: "Doit contenir au moins un chiffre",
      formatSpecial: "Doit contenir au moins un caractère spécial",
    },
    registrationSuccess:"Votre demande a bien été prise en compte, elle est en attente de validation d'un administrateur",
    registrationEmailError: "Adresse email déjà utilisée.",
    forgetPassword: "Mot de passe oublié",
    forgetPasswordMessage: "Saisissez votre adresse e-mail et nous vous enverrons des instructions pour réinitialiser votre mot de passe.",
    continue: "Continuer",
    goBackToLogin: "Retour à la connexion",
    newPasswordMailSuccess : "Votre demande a bien été prise en compte, vous allez recevoir un mail pour réinitialiser votre mot de passe.",
    newPasswordMailWarning: "Dans le cas où vous n'auriez pas reçu de mail, merci d'attendre 10min avant de renouveler la demande.",
    masterPassword: "Mot de passe actuel",
    legacy: "Bienvenue sur la nouvelle version de SuperWyze Studio! Un lien vous permettant de créer votre nouveau mot de passe vous a été envoyé sur votre adresse mail."
  },

  modal: {
    deletionValidation: "Suppression d'un élément",
    cancel: "Annuler",
    delete : "Supprimer",
    retry: "Réessayer"
  },

  institutions: {
    title: "Mes établissements",
    sites: "Les établissements",
    placeholder:"Projet / Établissement",
    create: "Créer un nouveau projet",
    selectSite: "Veuillez séléctionner un site",
    back: 'Retour aux institutions'
  },

  pairing: {
    methods: "Choisissez une de ces trois méthodes pour sélectionner un capteur",
    scan: "Scannez le capteur à l'aide de la douchette.",
    touchSensor: "Lancez la recherche en détectant le capteur au toucher.",
    search: {
      searchSensor: "Renseigner le n° d'identifiant du capteur"
    },
    touch: {
      noSensor: "Aucun capteur n'a été detecté",
      touch: "Touchez le capteur jusqu'à ce qu'il soit detecté"
    }
  },

  reportsAlerts: {
    title: 'Alertes',
    create: "Créer une nouvelle alerte",
    creationDate: "Date de création",
    type: "Type",
    zone: "Zone",
    equipments: "Équipements",
    alerts: "Actions",
    selectType: "Sélectionner un type d'alerte pour continuer",
    historic: "Historique des alertes créées",
    types: {
      out_site: "Hors site",
      out_area: "Hors zone",
      state: "État",
      approaching_date: "Date approchant",
      expired_date: "Date expirée",
      temperature: "Temperature",
      subtypes: {
        approaching_date: {
          ip_date: "Date IP approchant",
          acquisition_date: "Date acquisition approchant",
          last_inflation_date: "Date inflation approchant",
          requalification_date: "Date requalification approchant",
          commissioning_date: "Date commissioning approchant",
          maintenance_date: "Date maintenance approchant"
        },
        expired_date: {
          ip_date: "Date IP expirée",
          acquisition_date: "Date acquisition expirée",
          last_inflation_date: "Date inflation expirée",
          requalification_date: "Date requalification expirée",
          commissioning_date: "Date commissioning expirée",
          maintenance_date: "Date maintenance expirée"
        },
      }
    },
    noReports: "Aucune alerte",
    deleteReport: "Vous êtes sur le point de supprimer une alerte.",
    confirmDeleteReport: "Êtes vous certain ?",
    newAlert: "Nouvelle alerte",
    dateTypes: {
      acquisition_date: "Date acquisition",
      last_inflation_date: "Date dernier gonflage",
      ip_date: "Date IP",
      maintenance_date: "Date maintenance",
      commissioning_date: "Date mise en service",
      requalification_date: "Date requalification"
    }
  },

  dateTypes: {
    acquisition_date: "Date acquisition",
    last_inflation_date: "Date dernier gonflage",
    ip_date: "Date IP",
    maintenance_date: "Date maintenance",
    commissioning_date: "Date mise en service",
    requalification_date: "Date requalification"
  },

  sidebar: {
    groups: "groupes",
    engine: "moteur",
    administration: "administration",
    equipments: "équipement",
    material: "matériel",
  },

  parameters: {
    dashboard: {
      parameters: "Paramètres",
      buildings: "Batiments",
      services: "Services",
      equipments: "Équipements",
      infra: "Infrastructures",
      users: "Utilisateurs",
    },
    equipments:{
      new: "Nouvel équipement",
      current: "Équipements concernés",
      import: "Importer des équipements",
      downloadExample: "Télécharger le modèle de fichier CSV à compléter pour l'import",
      downloadFiles: "Télécharger les fichiers d'information pour compléter le document d'import",
      dlFile: "Fichier d'importation",
      dlFamily: "Familles et Modèles",
      dlServices: "Services",
      dlSensors: "Capteurs",
      back:"Retour à la liste",
      noEquipments: "Aucun équipement",
      failImport: "Erreur lors de l'import"
    },
    infra:{
      gatewaysCount: "passerelles concernées",
      connectionStatus: "État de la connexion",
      sensorName: "Nom du capteur",
      displayPairedSensors: "Afficher les capteurs appairés",
      displayUnpairedSensors: "Afficher les capteurs non appairés",
      sensorCount: "capteurs concernés",
      noSensors: "Aucun capteur",
      equipment: "Équipement"
    },
    services:{
      name: "Nom du service",
      new: "Nouveau service",
      count: "Services concernés",
      noServices: "Aucun service",
      roomsCount: "Nombre de pièces"
    },
    users:{
      new: "Nouvel utilisateur",
      user: "Un utilisateur",
      registered: "L'utilisateur {name} {lastName} a été enregistré",
      validationDone: "Validation effectuée, l'émail a été envoyé !",
      deleteUserErrorTitle: "Erreur de suppression",
      deleteUserErrorMessage: "Impossible de supprimer l'utilisateur !",
      deleteUserAssociationErrorTitle: "Associer l'utilisateur",
      deleteUserAssociationWarning: "Attention !",
      deleteUserAssociationMessage: "L'émail {tempUserEmail} est déjà associé à un utilisateur, voulez-vous l'associer à {institutionName} ?",
      deleteUserAssociationButton: "Associer",
      alreadyPaired: "L'utilisateur est associé à une autre institution",
      validatedAt: "Date d'inscription",
      connectedAt: "Connexion"
    }
  },

  navbar: {
    app_name: "SWStudio",
    admin: "administration",
    users: "utilisateurs",
    engineParameters: "paramètres moteur",
    deployment: "déploiement",
    records: "enregistrements",
    params: "paramétrisation",
  },

  routes: {
    names: {
      dashboard: "Tableau de Bord",
      location: "Localisation & Inventaire",
      pairing: "Associer",
      reports_alerts: "Alertes",
      alerts: "Alertes",
      parameters: "Paramètres",
      thermometry: "Surveillance de température"
    },
  },

  filtres: {
    familyName: "Famille",
    batiment: "Bâtiment",
    etage: "Etage"
  },

  states: {
    full: "Plein",
    empty: "Vide",
    available: "Disponible",
    not_available: "Indisponible",
    unavailable: "Indisponible",
    open: "Ouvert",
    closed: "Fermé",
    close: "Fermé",
    fill: "Plein",
    deflated: "Dégonflé",
    inflated: "Gonflé",
    inflating: "En gonflage",
    none: "-",
    None: "-",
    active: "Actif",
    inactive: "Inactif",
    approaching_date: "Date approchant",
    expired_date: "Date expirée"
  },

  sensors: {
    humidity: 'Humidité',
    temperature: 'Température',
    proximity: 'Proximité',
    touch: 'Tactile',
    co2: 'CO2',
    pressure: 'Pression',
    maintenance: 'Maintenance',
    contact: 'Contact'
  },
  dataType: {
    temperature: 'Températures',
    co2: 'CO2',
    humidity: 'Humidités'
  },
  thermometry: {
    ... FRENCH_TRANSLATIONS_SW_THERMOMETRY
  },
};
