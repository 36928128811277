
const equipmentsListState = {
    listVisible: false
};

const equipmentsListModule = {
    namespaced: true,
    state: equipmentsListState,
    getters: {},
    actions: {
    },
    mutations: {
        updateListVisibility(state, data) {
            state.listVisible = data;
        },
    },
    modules: {},
};

export default equipmentsListModule;