const navigationModule = {
    state: {
        mainRoute: null
    },
    mutations: {
        updateMainRoute(state, mainRoute) {
            state.mainRoute = mainRoute;
        }
    }
}

export default navigationModule;